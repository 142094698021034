import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

const Impressum = () => (
  <Layout lang="de" langPath="nav.imprint.link">
    <Seo
      title="Monday Consulting GmbH – Impressum"
      description="Impressum der Webseite der Monday Consulting GmbH."
      keywords="Impressum"
    />
    <div className="parallax-translate bg-shape-diamond" />
    <div className="container mt-4 mb-4">
      <div className="row">
        <div className="col-12">
          <h1 className="text-size-2 text-size-md-1">Impressum</h1>
        </div>
      </div>
    </div>
    <div className="container mt-4 mb-4 reveal">
      <div className="row">
        <div className="col-12">
          <p>
            Monday Consulting GmbH
            <br />
            Breite Str. 61
            <br />
            22767 Hamburg
            <br />
          </p>
          <p>
            Sitz der Gesellschaft: Hamburg
            <br />
            Registergericht: Amtsgericht Hamburg
            <br />
            Registernummer: HRB 109193
            <br />
            Umsatzsteuer-Identifikationsnummer gemäß § 27a
            <br />
            Umsatzsteuergesetz: DE265148776
          </p>
          <h2 className="h4">Kontakt</h2>
          <p>
            Telefon: +49 (0)40 22 86 842 0<br />
            Telefax: +49 (0)40 22 86 842 99
            <br />
            E-Mail:{" "}
            <a href="mailto:contact@monday-consulting.com">
              contact@monday-consulting.com
            </a>
            <br />
            Web: <Link to="/">www.monday-consulting.com</Link>
            <br />
          </p>
          <h4>Vertretungsberechtigte Geschäftsführer</h4>
          <p>
            Andreas Bögemann
            <br />
            Marcel Scheland
            <br />
            Dirk Schrödter
            <br />
            Volker Schrödter
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Impressum;
